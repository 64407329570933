import BaseRequest from "./BaseRequest";

export default class AccountRequest extends BaseRequest {
  signUpAdminAccount(params: any) {
    const url = "/signup";
    return this.post(url, params);
  }
  loginAdminAccount(params: any) {
    const url = "/login";
    return this.post(url, params);
    // if(res && res.data) {
    //   localStorage.setItem('accessToken', res.data.accessToken);
    //   return res.data;
    // }
  }
  changePassword(params: any) {
    const url = "/users/change-password";
    return this.put(url, params);
  }
  resetPassword(params: any) {
    const url = "/reset-password";
    return this.put(url, params);
  }
  getUsers(params: any) {
    const url = "/users";
    return this.get(url, params);
  }
  deleteUser(userId: any) {
    const url = `/users/${userId}`;
    return this.put(url, {});
  }
  updateUser(params: any) {
    const url = `/users`;
    return this.put(url, params);
  }
}
