import { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import rf from "../../requests/RequestFactory";
import CustomButton from "../../components/CustomButton";
import styles from "../../styles/pages/Staff.module.scss";
import StaffCard from "../../components/StaffCard";
import { useNavigate } from "react-router-dom";
import { Pagination } from "antd";

function Staff({ t }: any) {
  const STAFFS_PER_PAGE = 10;
  const navigate = useNavigate();
  const [staffList, setStaffList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totaltotalDocs, setTotaltotalDocs] = useState(1);

  async function getStaffList() {
    const params = {
      page: currentPage,
      limit: STAFFS_PER_PAGE,
    };
    const res = await rf.getRequest("StaffRequest").getStaffList(params);
    if (res && res.totalDocs > 0) {
      setStaffList(res.docs);
      setTotaltotalDocs(res.totalDocs);
    } else {
      setStaffList([]);
      setTotaltotalDocs(0);
    }
  }
  useEffect(() => {
    getStaffList();
    // eslint-disable-next-line
  }, [currentPage]);
  return (
    <div className={styles.staffPage}>
      <CustomButton onClick={() => navigate("/staff-create")}>
        + {t("new staff")}
      </CustomButton>
      {staffList && staffList.length > 0
        ? staffList.map(function (staff: any, index) {
            return (
              <StaffCard
                key={index}
                staffInfo={staff}
                refreshStaffList={() => getStaffList()}
              />
            );
          })
        : ""}
      {totaltotalDocs > STAFFS_PER_PAGE ? (
        <Pagination
          className={styles.pagination}
          current={currentPage}
          total={totaltotalDocs}
          pageSize={STAFFS_PER_PAGE}
          onChange={(e) => setCurrentPage(e)}
          showSizeChanger={false}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default withNamespaces()(Staff);
