import { useNavigate } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import rf from "../requests/RequestFactory";
import { useState } from "react";
import { validEmail, validPassword } from "../utils/utils";
import styles from "../styles/pages/SignUp.module.scss";
import CustomButton from "../components/CustomButton";
import { sha256 } from "js-sha256";
import { notification } from "antd";

function ChangePassword({ t }: any) {
  const navigate = useNavigate();
  //Params variables
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [retypePassword, setRetypePassword] = useState("");
  const handleChangePassword = async () => {
    if (isDisabledSubmit()) {
      return;
    }
    const params = {
      email: email,
      oldPassword: sha256(oldPassword),
      newPassword: sha256(password),
    };
    try {
      const res = await rf.getRequest("AccountRequest").changePassword(params);
      if (res) {
        notification.success({
          message: t("Change password successfully!"),
        });
        navigate("/");
      }
    } catch (error: any) {
      notification.error({ message: error.toString() });
    }
  };

  const isDisabledSubmit = () => {
    return (
      !email ||
      !password ||
      !oldPassword ||
      !validEmail.test(email) ||
      !validPassword.test(password) ||
      (password && retypePassword !== password)
    );
  };

  const validateMsg = () => {
    if (!email || !oldPassword || !password) {
      return "Please fill all fields above";
    }
    if (!validEmail.test(email)) {
      return "Invalid email format";
    }
    if (!validPassword.test(password)) {
      return "Invalid password format. Your password must have minimum six characters, one letter and one number.";
    }
    if (password && retypePassword !== password) {
      return "Unmatched retyped password";
    }
    return "";
  };

  return (
    <div className={styles.signupPage}>
      <div className={styles.brandWrapper}>
        <div className={styles.brandTitle}>Artist House</div>
        <div className={styles.brandSubtitle}>Admin Page</div>
      </div>
      <div className={styles.loginWrapper}>
        <input
          className={styles.emailInput}
          name="email"
          type="text"
          placeholder={t("Email") + " (abc@xyz.com)"}
          onChange={(e) => setEmail(e.target.value)}
        ></input>
        <input
          className={styles.passwordInput}
          name="password"
          type="password"
          placeholder={t("Old Password")}
          onChange={(e) => setOldPassword(e.target.value)}
        ></input>
        <input
          className={styles.passwordInput}
          name="password"
          type="password"
          placeholder={t("New Password")}
          onChange={(e) => setPassword(e.target.value)}
        ></input>
        <input
          className={styles.passwordInput}
          name="retype-password"
          type="password"
          placeholder={t("Retype New Password")}
          onChange={(e) => setRetypePassword(e.target.value)}
        ></input>
        <div className={styles.validateMsg}>{t(validateMsg())}</div>
        <CustomButton
          className={styles.loginButton}
          onClick={handleChangePassword}
        >
          {t("save changes")}
        </CustomButton>
      </div>
    </div>
  );
}

export default withNamespaces()(ChangePassword);
