import { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import rf from "../../requests/RequestFactory";
import styles from "../../styles/pages/BookingCreate.module.scss";
import CustomButton from "../../components/CustomButton";
import { useNavigate, useParams } from "react-router-dom";
import { DatePicker, DatePickerProps, notification, Pagination } from "antd";
import {
  numberWithCommas,
  getTimeString,
  getDateFromTimestamp,
} from "../../utils/utils";
import moment from "moment";

function BookingEdit({ t }: any) {
  const navigate = useNavigate();
  const SERVICES_PER_PAGE = 10;
  const urlParams = useParams();
  const [staffList, setStaffList] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [availableTimes, setAvailableTimes] = useState<
    Array<{ startTime: Number; text: String }>
  >([]);
  const [currentServicePage, setCurrentServicePage] = useState(1);
  const [totalServiceDocs, setTotalServiceDocs] = useState(0);
  //Param variables
  const [status, setStatus] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [comment, setComment] = useState("");
  const [staff, setStaff] = useState(undefined as any);
  const [dateTime, setDateTime] = useState<any>(
    new Date().toISOString().substring(0, 10)
  );
  const [selectedTime, setSelectedTime] = useState<any>();
  const [services, setServices] = useState([] as any);

  const handleAddBooking = async () => {
    if (isDisabledAdding()) {
      notification.error({ message: t("Please fill all required fields!") });
      return;
    }
    const params = {
      branch: (branchList[0] as any)._id,
      _id: urlParams.bookingId,
      name: customerName,
      email: customerEmail,
      phone: customerPhone.toString(),
      comment: comment,
      staff: staff._id,
      status: status,
      startTimeBooking: selectedTime?.startTime,
      startDateBooking: new Date(
        new Date(dateTime).toISOString().substring(0, 10)
      ).valueOf(),
      services: services.map((service: any) => service._id),
    };
    try {
      const res = await rf.getRequest("BookingRequest").editBooking(params);
      if (res) {
        notification.success({ message: t("Edit booking successfully!") });
        navigate("/");
      }
    } catch (error: any) {
      notification.error({ message: error.toString() });
    }
  };

  const isDisabledAdding = () => {
    return (
      !customerName ||
      !customerEmail ||
      !customerPhone ||
      !staff ||
      !selectedTime ||
      !dateTime ||
      !services ||
      services.length <= 0
    );
  };

  async function getStaffOption() {
    const params = {
      services: services.map((service: any) => service._id).toString(),
      page: 1,
      limit: 200,
    };
    const res = await rf.getRequest("StaffRequest").getStaffList(params);
    if (res) {
      setStaffList(res.docs);
    }
  }

  async function getServiceOption() {
    const params = {
      page: 0,
      limit: 200,
    };
    const res = await rf.getRequest("ServiceRequest").getServiceList(params);
    if (res && res.totalDocs > 0) {
      setServiceList(res.docs);
      setTotalServiceDocs(res.totalDocs);
    } else {
      setServiceList([]);
      setTotalServiceDocs(0);
    }
  }

  async function getBranchOption() {
    const res = await rf.getRequest("BranchRequest").getBranchList();
    if (res && res.length > 0) {
      setBranchList(res);
    }
  }

  const getAvailableTime = async () => {
    const params = {
      staffId: staff?._id,
      date: new Date(dateTime).valueOf().toString(),
    };
    const res = await rf.getRequest("BookingRequest").getAvaiableTime(params);
    if (res) {
      setAvailableTimes(res);
    }
  };

  const onChange: DatePickerProps["onChange"] = (date, dateString) => {
    setDateTime(dateString);
  };

  const handleCheckServices = (checkService: any) => {
    let newSelectedServices = Array.from(services);
    if (
      newSelectedServices.find(
        (service: any) => service._id === checkService._id
      )
    ) {
      newSelectedServices = newSelectedServices.filter(
        (service: any) => service._id !== checkService._id
      );
    } else {
      newSelectedServices.push(checkService);
    }
    setServices(newSelectedServices);
  };

  async function getBookingDetail() {
    const res = await rf
      .getRequest("BookingRequest")
      .getBookingById(urlParams.bookingId);
    if (res) {
      setCustomerName(res.customer.name);
      setCustomerEmail(res.customer.email);
      setCustomerPhone(res.customer.phone);
      setComment(res.comment);
      setStaff(res.staff);
      setDateTime(res.startDateBooking);
      setSelectedTime({
        startTime: res.startTimeBooking,
        text: getTimeString(res.startTimeBooking),
      });
      setServices(res.services);
      setStatus(res.status);
    }
  }

  useEffect(() => {
    getBookingDetail();
    getBranchOption();
    getStaffOption();
    getAvailableTime();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    getAvailableTime();
    if (staff) {
      setServiceList(staff?.services);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staff, dateTime]);
  useEffect(() => {
    getStaffOption();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [services]);
  useEffect(() => {
    getServiceOption();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentServicePage])
  return (
    <div className={styles.bookingCreatePage}>
      <div className={styles.bookingInfo}>
        <div className={styles.bookingDescription}>
          <div className={styles.infoTitle}>
            {t(`Customer Name`)}
            <span className="requiredFieldIcon">*</span>
          </div>
          <input
            className={styles.emailInput}
            name="name"
            type="text"
            value={customerName}
            placeholder={t(`Customer Name`)}
            onChange={(e) => setCustomerName(e.target.value)}
          ></input>
          <div className={styles.infoTitle}>
            {t(`Customer Email`)}
            <span className="requiredFieldIcon">*</span>
          </div>
          <input
            className={styles.emailInput}
            name="name"
            type="text"
            value={customerEmail}
            placeholder={t(`Customer Email`)}
            onChange={(e) => setCustomerEmail(e.target.value)}
          ></input>
          <div className={styles.infoTitle}>
            {t(`Customer Phone`)}
            <span className="requiredFieldIcon">*</span>
          </div>
          <input
            className={styles.emailInput}
            name="name"
            type="number"
            value={customerPhone}
            placeholder={t(`Customer Phone`)}
            onChange={(e) => setCustomerPhone(e.target.value)}
          ></input>
          {staffList && staffList.length > 0 ? (
            <>
              <div className={styles.infoTitle}>
                {t(`Specialist`)}
                <span className="requiredFieldIcon">*</span>
              </div>
              <div className={styles.staffOption}>
                {staffList.map((staff: any, index) => (
                  <div
                    className={styles.staffWrapper}
                    key={index}
                    onClick={() => setStaff(staff)}
                  >
                    <img
                      className={styles.staffAvatar}
                      src={staff.imageUrl || '/images/blank-profile-picture.png'}
                      alt="staff-avatar"
                    />
                    <div className={styles.staffName}>{staff.name}</div>
                    <div className={styles.staffExp}>{staff.exp}</div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            ""
          )}
          <div className={styles.infoTitle}>
            {t(`Date & Time`)}
            <span className="requiredFieldIcon">*</span>
          </div>
          <DatePicker
            style={{ width: "100%" }}
            defaultValue={moment(dateTime)}
            onChange={onChange}
          />
          <div className={styles.serviceOption}>
            {availableTimes.map((time, index) => (
              <div
                className={`${styles.serviceWrapper} ${
                  selectedTime?.startTime === time.startTime
                    ? styles.selected
                    : ""
                }`}
                key={index}
                onClick={() => setSelectedTime(time)}
              >
                <div className={styles.name}> {time.text} </div>
              </div>
            ))}
          </div>
          {serviceList && serviceList.length > 0 ? (
            <>
              <div className={styles.infoTitle}>
                {t(`Service`)}
                <span className="requiredFieldIcon">*</span>
              </div>
              <div className={styles.serviceOption}>
                {serviceList.map((service: any, index) => (
                  <div
                    // className={`${styles.serviceWrapper} ${services.includes(service) ? styles.selected : ''}`}
                    className={`
                      ${styles.serviceWrapper} 
                      ${
                        services
                          .map((service: any) => service._id)
                          .includes(service._id)
                          ? styles.selected
                          : ""
                      }
                    `}
                    key={index}
                    onClick={() => handleCheckServices(service)}
                  >
                    <div className={styles.serviceName}>{service.name}</div>
                    <div className={styles.servicePrice}>
                      ₫{numberWithCommas(service.price)}
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            ""
          )}
          {totalServiceDocs > SERVICES_PER_PAGE ? (
            <Pagination
              className={styles.pagination}
              current={currentServicePage}
              total={totalServiceDocs}
              pageSize={SERVICES_PER_PAGE}
              onChange={(e) => setCurrentServicePage(e)}
            />
          ) : (
            ""
          )}
          <div className={styles.infoTitle}>{t("Comment")}</div>
          <input
            className={styles.emailInput}
            name="comment"
            type="text"
            value={comment}
            placeholder={t("Comment")}
            onChange={(e) => setComment(e.target.value)}
          ></input>
        </div>
        <div className={styles.bookingPreview}>
          <div className={styles.previewTitle}>{t("Booking Preview")}</div>
          {staff ? (
            <>
              <div className={styles.infoTitle}>{t("Specialist")}</div>
              <div className={styles.staffWrapper}>
                <img
                  className={styles.staffAvatar}
                  src={staff.imageUrl || '/images/blank-profile-picture.png'}
                  alt="staff-avatar"
                />
                <div className={styles.staffName}>{staff.name}</div>
                <div className={styles.staffExp}>{staff.exp}</div>
              </div>
            </>
          ) : (
            ""
          )}
          {dateTime && selectedTime && selectedTime.text ? (
            <>
              <div className={styles.infoTitle}>{t("Date & Time")}</div>
              <div className={styles.dateTimeWrapper}>
                {getDateFromTimestamp(dateTime)} - {selectedTime.text}
              </div>
            </>
          ) : (
            ""
          )}
          {services && services.length > 0 ? (
            <>
              <div className={styles.infoTitle}>{t(`Service`)}</div>
              <div className={styles.serviceOption}>
                {services.map((service: any, index: number) => (
                  <div className={styles.serviceWrapper} key={index}>
                    <div className={styles.serviceName}>{service.name}</div>
                    <div className={styles.servicePrice}>
                      ₫{numberWithCommas(service.price)}
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className={styles.buttonWrapper}>
        <CustomButton onClick={handleAddBooking}>
          {t("save changes")}
        </CustomButton>
        <CustomButton
          onClick={() => navigate("/service")}
          buttonColor={"white"}
        >
          {t("cancel")}
        </CustomButton>
      </div>
    </div>
  );
}

export default withNamespaces()(BookingEdit);
