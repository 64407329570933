import { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import rf from "../../requests/RequestFactory";
import styles from "../../styles/pages/ServiceCreate.module.scss";
import CustomButton from "../../components/CustomButton";
import { useNavigate, useParams } from "react-router-dom";
import { notification } from "antd";

function ServiceEdit({ t }: any) {
  const navigate = useNavigate();
  const urlParams = useParams();
  const [businessList, setBusinessList] = useState([]);
  //Param variables
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [minTime, setMinTime] = useState(0);
  const [maxTime, setMaxTime] = useState(1);
  const [type, setType] = useState("");
  const [description, setDescription] = useState("");

  const handleAddService = async () => {
    if(isDisabledAddingService()) {
      notification.error({message: t('Please fill all required fields!')});
      return;
    }
    const params = {
      _id: urlParams.serviceId,
      name: name,
      price: price,
      duration: {
        minTime: minTime,
        maxTime: maxTime,
      },
      type: type,
      description: description,
    };
    try {
      const res = await rf.getRequest("ServiceRequest").editService(params);
      if (res) {
        notification.success({ message: t("Edit serivce successfully!") });
        navigate("/service");
      }
    } catch (error: any) {
      notification.error({ message: error.toString() });
    }
  };
  
  const isDisabledAddingService = () => {
    return !name || !price || !minTime || !maxTime || !type || !description;
  }

  async function getBusinessOption() {
    const res = await rf.getRequest("BusinessRequest").getBusinessList();
    if (res && res.length > 0) {
      setBusinessList(res);
      setType(res[0]);
    }
  }

  async function getServiceDetail() {
    const res = await rf
      .getRequest("ServiceRequest")
      .getServiceById(urlParams.serviceId);
    if (res) {
      setName(res.name);
      setType(res.type._id);
      setMinTime(res.duration.minTime);
      setMaxTime(res.duration.maxTime);
      setDescription(res.description);
      setPrice(res.price);
    }
  }

  useEffect(() => {
    getServiceDetail();
    getBusinessOption();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.serviceCreatePage}>
      <div className={styles.serviceInfo}>
        <div className={styles.serviceDescription}>
          <div className={styles.infoTitle}>{t("Service Name")}<span className='requiredFieldIcon'>*</span></div>
          <input
            value={name}
            className={styles.emailInput}
            name="name"
            type="text"
            placeholder={t("Service Name")}
            onChange={(e) => setName(e.target.value)}
          ></input>
          {businessList && businessList.length > 0 ? (
            <>
              <div className={styles.infoTitle}>{t("Category")}<span className='requiredFieldIcon'>*</span></div>
              <select value={type} onChange={(e) => setType(e.target.value)}>
                {businessList.map((option: any, index) => (
                  <option value={option._id} key={index}>
                    {t(option.name)}
                  </option>
                ))}
              </select>
            </>
          ) : (
            ""
          )}
          <div className={styles.infoTitle}>{t('Service Duration')} ({t('Hour')})<span className='requiredFieldIcon'>*</span></div>
          <div className={styles.durationWrapper}>
            <div>{t("from")}</div>
            <input
              className={styles.durationInput}
              name="minTime"
              type="number"
              value={minTime}
              step={0.5}
              placeholder={t("Min Time")}
              onChange={(e) => setMinTime(Number(e.target.value))}
            ></input>
            <div>{t("to")}</div>
            <input
              className={styles.durationInput}
              name="maxTime"
              type="number"
              value={maxTime}
              step={0.5}
              placeholder={t("Max Time")}
              onChange={(e) => setMaxTime(Number(e.target.value))}
            ></input>
          </div>
          <div className={styles.infoTitle}>{t("Service Description")}<span className='requiredFieldIcon'>*</span></div>
          <input
            className={styles.emailInput}
            name="description"
            type="text"
            value={description}
            placeholder={t("Some description about the service")}
            onChange={(e) => setDescription(e.target.value)}
          ></input>
          <div className={styles.infoTitle}>{t('Service Price')} (VND)<span className='requiredFieldIcon'>*</span></div>
          <input
            className={styles.emailInput}
            name="price"
            type="number"
            value={price}
            placeholder={t("Price")}
            onChange={(e) => setPrice(e.target.value)}
          ></input>
        </div>
      </div>
      <div className={styles.buttonWrapper}>
        <CustomButton onClick={handleAddService}>
          {t("save changes")}
        </CustomButton>
        <CustomButton
          onClick={() => navigate("/service")}
          buttonColor={'white'}
        >
          {t("cancel")}
        </CustomButton>
      </div>
    </div>
  );
}

export default withNamespaces()(ServiceEdit);
