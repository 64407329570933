import { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import rf from "../../requests/RequestFactory";
import styles from "../../styles/pages/StaffCreate.module.scss";
import CustomButton from "../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import { notification, Pagination } from "antd";

function StaffCreate({ t }: any) {
  const navigate = useNavigate();
  const SERVICES_PER_PAGE = 10;
  const [roleList, setRoleList] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [currentServicePage, setCurrentServicePage] = useState(1);
  const [totalServiceDocs, setTotalServiceDocs] = useState(0);
  //Param variables
  const [name, setName] = useState("");
  const [staffId, setStaffId] = useState("");
  const [role, setRole] = useState("");
  const [services, setServices] = useState([] as any[]);
  const [exp, setExp] = useState("");
  const [file, setFile] = useState<any>();
  const [startTimeWorking, setStartTimeWorking] = useState(undefined as any);
  const [endTimeWorking, setEndTimeWorking] = useState(undefined as any);

  const isInvalidRequestParams = () => {
    return (
      !staffId || !name || !role || !exp || !startTimeWorking || !endTimeWorking
    );
  };

  const handleAddStaff = async () => {
    if (isInvalidRequestParams()) {
      notification.error({ message: t("Please fill all required fields!") });
      return;
    }
    var profilePictureFile = new FormData();
    profilePictureFile.append("branch", (branchList[0] as any)._id);
    profilePictureFile.append("file", file);
    profilePictureFile.append("id", staffId);
    profilePictureFile.append("name", name);
    profilePictureFile.append("role", role);
    profilePictureFile.append("exp", exp);
    profilePictureFile.append("startTimeWorking", startTimeWorking);
    profilePictureFile.append("endTimeWorking", endTimeWorking);
    services.forEach((service) =>
      profilePictureFile.append("services", service._id)
    );
    profilePictureFile.append("user", "hubot");
    try {
      const res = await rf
        .getRequest("StaffRequest")
        .addStaff(profilePictureFile);
      if (res) {
        notification.success({ message: t("Add staff successfully!") });
        navigate("/staff");
      }
    } catch (error: any) {
      notification.error({ message: error.toString() });
    }
  };

  async function getRoleOption() {
    const res = await rf.getRequest("RoleRequest").getRoleList();
    if (res && res.length > 0) {
      setRoleList(res);
      setRole(res[0]._id);
    }
  }

  async function getServiceOption() {
    const params = {
      page: currentServicePage,
      limit: SERVICES_PER_PAGE,
    };
    const res = await rf.getRequest("ServiceRequest").getServiceList(params);
    if (res && res.totalDocs > 0) {
      setServiceList(res.docs);
      setTotalServiceDocs(res.totalDocs);
    } else {
      setServiceList([]);
      setTotalServiceDocs(0);
    }
  }

  async function getBranchOption() {
    const res = await rf.getRequest("BranchRequest").getBranchList();
    if (res && res.length > 0) {
      setBranchList(res);
    }
  }

  const handleCheckServices = (service: any) => {
    var servicesForm = [...services];
    if (servicesForm.includes(service)) {
      servicesForm.splice(services.indexOf(service), 1);
    } else {
      servicesForm = [...services, service];
    }
    setServices(servicesForm);
  };

  function loadFile(e: any) {
    const previewPhoto = document.getElementById(
      "previewPhoto"
    ) as HTMLImageElement;
    setFile(e.target.files[0]);
    previewPhoto.src = URL.createObjectURL(e.target.files[0]);
    previewPhoto.onload = function () {
      URL.revokeObjectURL(previewPhoto.src); // free memory
    };
  }

  useEffect(() => {
    getBranchOption();
    getRoleOption();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getServiceOption();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentServicePage])

  return (
    <div className={styles.staffCreatePage}>
      <div className={styles.staffInfo}>
        <div className={styles.profilePictureUpload}>
          <img
            className={styles.previewPhoto}
            src="/images/blank-profile-picture.png"
            id="previewPhoto"
            alt="preview-avatar"
          />
          <CustomButton className={styles.uploadButton}>
            {t("choose profile picture")}
            <input
              className={styles.uploadInput}
              type="file"
              accept="image/*"
              onChange={(e) => loadFile(e)}
            />
          </CustomButton>
          <div className={styles.profilePictureHint}>
            {t("profilePictureHint")}
          </div>
        </div>
        <div className={styles.staffDescription}>
          <div className={styles.infoTitle}>
            {t("Staff Name")}
            <span className="requiredFieldIcon">*</span>
          </div>
          <input
            className={styles.emailInput}
            name="name"
            type="text"
            placeholder={t("Full Name")}
            onChange={(e) => setName(e.target.value)}
          ></input>
          <div className={styles.infoTitle}>
            {t("Staff ID")}
            <span className="requiredFieldIcon">*</span>
          </div>
          <input
            className={styles.emailInput}
            name="id"
            type="number"
            placeholder={t("ID Number")}
            onChange={(e) => setStaffId(e.target.value)}
          ></input>
          {roleList && roleList.length > 0 ? (
            <>
              <div className={styles.infoTitle}>
                {t("Role")}
                <span className="requiredFieldIcon">*</span>
              </div>
              <select value={role} onChange={(e) => setRole(e.target.value)}>
                {roleList.map((option: any, index) => (
                  <option value={option._id} key={index}>
                    {t(
                      option.name.charAt(0).toUpperCase() + option.name.slice(1)
                    )}
                  </option>
                ))}
              </select>
            </>
          ) : (
            <>
              <div className={styles.infoTitle}>
                {t("Role")}
                <span className="requiredFieldIcon">*</span>
              </div>
              <input
                className={styles.passwordInput}
                name="role"
                type="text"
                placeholder={t("Role") + " (Manger, Staff, ...)"}
                onChange={(e) => setRole(e.target.value)}
              ></input>
            </>
          )}
          <div className={styles.infoTitle}>
            {t("Working Hour")} ({t("Hour")})
            <span className="requiredFieldIcon">*</span>
          </div>
          <div className={styles.durationWrapper}>
            <div>{t("from")}</div>
            <input
              className={styles.durationInput}
              name="minTime"
              type="number"
              value={startTimeWorking}
              placeholder={t("Start Time")}
              onChange={(e) => setStartTimeWorking(Number(e.target.value))}
            ></input>
            <div>{t("to")}</div>
            <input
              className={styles.durationInput}
              name="maxTime"
              type="number"
              value={endTimeWorking}
              placeholder={t("End Time")}
              onChange={(e) => setEndTimeWorking(Number(e.target.value))}
            ></input>
          </div>
          {serviceList && serviceList.length > 0 ? (
            <>
              <div className={styles.infoTitle}>{t(`Service`)}</div>
              <div className={styles.serviceOption}>
                {serviceList.map((service: any, index) => (
                  <div
                    className={`${styles.serviceWrapper} ${
                      services.map((service: any) => service._id)
                      .includes(service._id)  ? styles.selected : ""
                    }`}
                    key={index}
                    onClick={() => handleCheckServices(service)}
                  >
                    <div className={styles.serviceName}>{service.name}</div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            ""
          )}
          {totalServiceDocs > SERVICES_PER_PAGE ? (
            <Pagination
              className={styles.pagination}
              current={currentServicePage}
              total={totalServiceDocs}
              pageSize={SERVICES_PER_PAGE}
              onChange={(e) => setCurrentServicePage(e)}
            />
          ) : (
            ""
          )}
          <div className={styles.infoTitle}>
            {t("Experience")}
            <span className="requiredFieldIcon">*</span>
          </div>
          <input
            className={styles.emailInput}
            name="name"
            type="text"
            placeholder={t("Experience")}
            onChange={(e) => setExp(e.target.value)}
          ></input>
        </div>
      </div>
      <div className={styles.buttonWrapper}>
        <CustomButton onClick={handleAddStaff}>{t("add staff")}</CustomButton>
        <CustomButton onClick={() => navigate("/staff")} buttonColor={"white"}>
          {t("cancel")}
        </CustomButton>
      </div>
    </div>
  );
}

export default withNamespaces()(StaffCreate);
