import { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import rf from "../../requests/RequestFactory";
import styles from "../../styles/pages/Booking.module.scss";
import BookingCard from "../../components/BookingCard";
import { Pagination } from "antd";
import { BookingOverview } from "../../types";

function Booking({ t }: any) {
  const [bookingList, setBookingList] = useState([]);
  const [status, setStatus] = useState("completed");
  const BOOKINGS_PER_PAGE = 3;
  const [currentBookingPage, setCurrentBookingPage] = useState(1);
  const [totalBookingDocs, setTotalBookingDocs] = useState(0);
  const [overview, setOverview] = useState<BookingOverview>({
    cancelled: 0,
    completed: 0,
    confirmed: 0,
    inprogress: 0,
    pending: 0,
  });

  async function getBookingOverview() {
    try {
      const res = await rf.getRequest("BookingRequest").getOverview();
      if (res) {
        setOverview(res);
      }
    } catch (error) {
      console.log(error);
    }
  }
  async function getBookingList() {
    const params = {
      status: status,
      page: currentBookingPage,
      limit: BOOKINGS_PER_PAGE,
    };
    const res = await rf.getRequest("BookingRequest").getBookingList(params);
    if (res && res.totalDocs > 0) {
      setBookingList(res.docs);
      setTotalBookingDocs(res.totalDocs);
    } else {
      setBookingList([]);
      setTotalBookingDocs(0);
    }
  }

  useEffect(() => {
    getBookingList();
  }, [currentBookingPage]);

  const handlePageChange = (status: string) => {
    setStatus(status);
    setCurrentBookingPage(1);
  };

  useEffect(() => {
    getBookingList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    getBookingOverview();
  }, []);
  return (
    <div className={styles.bookingPage}>
      <div className={"tab"}>
        <button
          className={`${"tabButton"} ${status === "completed" ? "active" : ""}`}
          onClick={() => handlePageChange("completed")}
        >
          <img src="/icons/check.svg" alt="check" />
          {t("completed")} ({overview.completed})
        </button>
        <button
          className={`${"tabButton"} ${status === "cancelled" ? "active" : ""}`}
          onClick={() => handlePageChange("cancelled")}
        >
          <img src="/icons/cross.svg" alt="check" />
          {t("cancelled")} ({overview.cancelled})
        </button>
      </div>
      {bookingList && bookingList.length > 0 ? (
        bookingList.map(function (booking: any, index) {
          return (
            <BookingCard
              bookingInfo={booking}
              refreshBookingList={getBookingList}
              key={index}
            />
          );
        })
      ) : (
        <div className={styles.noResult}>
          <img src="/icons/search.svg" alt="search" />
          {t("No Result Found")}
        </div>
      )}
      {totalBookingDocs > BOOKINGS_PER_PAGE ? (
        <Pagination
          className={styles.pagination}
          current={currentBookingPage}
          total={totalBookingDocs}
          pageSize={BOOKINGS_PER_PAGE}
          onChange={(e) => setCurrentBookingPage(e)}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default withNamespaces()(Booking);
