import styles from '../styles/components/DeleteButton.module.scss';

function DeleteButton(props: any) {
  const { onClick } = props;
  return (
    <div className={styles.deleteButton} onClick={onClick}>
      <img src='/icons/recycled-bin.svg' alt='delete-button'/>
    </div>
  );
}

export default DeleteButton;