import BaseRequest from "./BaseRequest";

export default class ServiceRequest extends BaseRequest {
  getServiceList(params: any) {
    const url = "/services";
    return this.get(url, params);
  }
  getServiceById(id: any) {
    const url = `/services/${id}`;
    return this.get(url, {});
  }
  addService(params: any) {
    const url = "/services";
    return this.post(url, params);
  }
  deleteService(id: any) {
    const url = `/services/${id}`;
    return this.put(url, {});
  }
  editService(params: any) {
    const url = "/services";
    return this.put(url, params);
  }
}
