import React, { useContext } from "react";
import { Menu } from "antd";
import i18n from "../i18n";
import { withNamespaces } from "react-i18next";
import "../styles/components/RightMenu.scss";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "..";

function RightMenu(props: any) {
  const navigate = useNavigate();
  const { direction, handleCollapse, t } = props;
  const { setAccessToken, setIsLoggedIn } = useContext(AuthContext);
  const changeLanguage = (lng: any) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng);
    handleCollapse();
  };
  const handleLogOut = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("accountRole");
    setAccessToken("");
    setIsLoggedIn(false);
    handleCollapse();
    navigate("/login");
  };
  return (
    <Menu mode={direction} disabledOverflow={true}>
      {direction === "vertical" ? (
        <Menu.Item key="collapse-button" onClick={handleCollapse}>
          <img src="/icons/arrow-right.svg" alt="collapse-button" />
        </Menu.Item>
      ) : (
        ""
      )}
      {localStorage.getItem("accessToken") ? (
        <>
          {localStorage.getItem('accountRole') === 'Admin' ?
            <Menu.Item key="account">
              <Link to="/account" onClick={handleCollapse}>{t("Account")}</Link>
            </Menu.Item> : ''
          }
          <Menu.Item key="bookingHistory">
            <Link to="/booking-history" onClick={handleCollapse}>{t("Booking History")}</Link>
          </Menu.Item>
          <Menu.Item key="staff">
            <Link to="/staff" onClick={handleCollapse}>{t("Staff")}</Link>
          </Menu.Item>
          <Menu.Item key="service">
            <Link to="/service" onClick={handleCollapse}>{t("Service")}</Link>
          </Menu.Item>
          <Menu.Item key="feedback">
            <Link to="/feedback" onClick={handleCollapse}>{t("Feedback")}</Link>
          </Menu.Item>
          <Menu.Item key="logout">
            <div onClick={() => handleLogOut()}>{t("Log out")}</div>
          </Menu.Item>
        </>
      ) : (
        ""
      )}
      <Menu.Item key="language" className="language-menu">
        <span onClick={() => changeLanguage("vi")}>
          <img
            className={
              "flag" +
              (localStorage.getItem("language") === "vi" ? " selected" : "")
            }
            src="/images/viet-nam.svg"
            alt="vn-flag"
          />
        </span>{" "}
        |{" "}
        <span onClick={() => changeLanguage("en")}>
          <img
            className={
              "flag" +
              (localStorage.getItem("language") === "en" ||
              !localStorage.getItem("language")
                ? " selected"
                : "")
            }
            src="/images/united-states.svg"
            alt="us-flag"
          />
        </span>
      </Menu.Item>
    </Menu>
  );
}
export default withNamespaces()(RightMenu);
