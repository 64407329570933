import BaseRequest from "./BaseRequest";

export default class BookingRequest extends BaseRequest {
  getBookingList(params: any) {
    const url = "/booking";
    return this.get(url, params);
  }
  getBookingById(id: any) {
    const url = `/booking/${id}`;
    return this.get(url, {});
  }
  addBooking(params: any) {
    const url = "/booking";
    return this.post(url, params);
  }
  // deleteBooking(id: any) {
  //   const url = `/booking/${id}`;
  //   return this.delete(url, {});
  // }
  editBooking(params: any) {
    const url = "/booking";
    return this.put(url, params);
  }
  getAvaiableTime(params: any) {
    const url = "/booking/available-time";
    return this.get(url, params);
  }
  cancelBooking(id: any, params: any) {
    const url = `/booking/${id}/cancel`;
    return this.put(url, params);
  }
  getOverview() {
    const url = `/booking/overview`;
    return this.get(url);
  }
}
