import { withNamespaces } from "react-i18next";
import styles from "../styles/components/FeedbackCard.module.scss";
import rf from "../requests/RequestFactory";
import DeleteButton from "./DeleteButton";
import EditButton from "./EditButton";
import ConfirmModal from "./ConfirmModal";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { notification } from "antd";

function FeedbackCard(props: any) {
  const navigate = useNavigate();
  const { feedbackInfo, refreshFeedbackList, t } = props;
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);

  const handleEditFeedback = async () => {
    navigate(`/feedback-edit/${feedbackInfo._id}`);
  };

  //Delete Feedback Modal
  const openDeleteModal = () => {
    setIsShowDeleteModal(true);
  };
  const closeDeleteModal = () => {
    setIsShowDeleteModal(false);
  };
  const handleDeleteFeedback = async () => {
    try {
      const res = await rf
        .getRequest("FeedbackRequest")
        .deleteFeedback(feedbackInfo._id);
      if (res) {
        notification.success({ message: t("Delete feedback successfully!") });
        refreshFeedbackList();
        setIsShowDeleteModal(false);
      }
    } catch (error: any) {
      notification.error({ message: error.toString() });
      setIsShowDeleteModal(false);
    }
  };
  return (
    <>
      <div className={styles.feedbackCard}>
        <div className={styles.feedbackInfo}>
          <EditButton onClick={handleEditFeedback} />
          <img
            className={styles.customerProfilePicture}
            src={
              feedbackInfo.imageUrl
                ? feedbackInfo.imageUrl
                : "/images/blank-profile-picture.png"
            }
            alt="customer-avatar"
          />
          <div className={styles.customerDescription}>
            <div className={styles.customerName}>
              {feedbackInfo.customerName}
            </div>
            <div className={styles.customerRole}>
              {feedbackInfo.description}
            </div>
          </div>
        </div>
        <DeleteButton onClick={openDeleteModal} />
      </div>
      <ConfirmModal
        isModalVisible={isShowDeleteModal}
        handleConfirm={handleDeleteFeedback}
        handleCancel={closeDeleteModal}
        title={t("Delete Feedback")}
      >
        {t("Are you sure to delete")} {t("this feedback")}? {t("This action cannot be undone!")}
      </ConfirmModal>
    </>
  );
}

export default withNamespaces()(FeedbackCard);
