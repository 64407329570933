import { useNavigate } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import rf from "../requests/RequestFactory";
import { useState } from "react";
import { validEmail } from "../utils/utils";
import styles from "../styles/pages/SignUp.module.scss";
import CustomButton from "../components/CustomButton";
import { notification } from "antd";

function ResetPassword({ t }: any) {
  const navigate = useNavigate();
  //Params variables
  const [email, setEmail] = useState("");
  const handleChangePassword = async () => {
    if (isDisabledSubmit()) {
      return;
    }
    const params = {
      email: email,
    };
    try {
      const res = await rf.getRequest("AccountRequest").resetPassword(params);
      if (res) {
        notification.success({
          message:
            t("Reset password successfully. Please check your email!")
        });
        navigate("/");
      }
    } catch (error: any) {
      notification.error({ message: error.toString() });
    }
  };

  const isDisabledSubmit = () => {
    return !email || !validEmail.test(email);
  };

  const validateMsg = () => {
    if (!email)
      return "Please fill all fields above";
    if (!validEmail.test(email)) {
      return "Invalid email format!";
    }
    return "";
  };

  return (
    <div className={styles.signupPage}>
      <div className={styles.brandWrapper}>
        <div className={styles.brandTitle}>Artist House</div>
        <div className={styles.brandSubtitle}>Admin Page</div>
      </div>
      <div className={styles.loginWrapper}>
        <input
          className={styles.emailInput}
          name="email"
          type="text"
          placeholder={t("Email") + " (abc@xyz.com)"}
          onChange={(e) => setEmail(e.target.value)}
        ></input>

        <div className={styles.validateMsg}>{t(validateMsg())}</div>
        <CustomButton
          className={styles.loginButton}
          onClick={handleChangePassword}
        >
          Reset
        </CustomButton>
      </div>
    </div>
  );
}

export default withNamespaces()(ResetPassword);
