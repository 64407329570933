import { notification } from 'antd';
import axios from 'axios';
import rf from '../requests/RequestFactory';

export const setAuthorizationToRequest = (accessToken: string) => {
  axios.defaults.headers.common['x-access-token'] = accessToken;
};

export const login = async (params: any) => {
  try {
    const res = await rf.getRequest('AccountRequest').loginAdminAccount(params);
    if(res) {
      notification.success({message: 'Logged in successfully!'});
      localStorage.setItem('accountRole', res.role.name);
      localStorage.setItem('accessToken', res.accessToken);
    }
  } catch (error: any) {
    notification.error({
      placement: 'topRight',
      bottom: 50,
      duration: 3,
      message: error.toString()
    });
  } 
  return Promise.resolve();
}
