import BaseRequest from "./BaseRequest";

export default class StaffRequest extends BaseRequest {
  getStaffList(params: any) {
    const url = '/staffs';
    return this.get(url, params);
  }
  getStaffById(id: any) {
    const url = `/staffs/${id}`;
    return this.get(url, {});
  }
  addStaff(params: any) {
    const url = "/staffs";
    return this.post(url, params);
  }
  deleteStaff(id: any) {
    const url = `/staffs/${id}`;
    return this.put(url, {});
  }
  editStaff(params: any) {
    const url = "/staffs";
    return this.put(url, params);
  }
}
