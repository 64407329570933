import styles from '../styles/components/EditButton.module.scss';

function EditButton(props: any) {
  const { onClick } = props;
  return (
    <div className={styles.editButton} onClick={onClick}>
      <img src='/icons/edit.svg' alt='edit-button'/>
    </div>
  );
}

export default EditButton;