import { withNamespaces } from 'react-i18next';
import styles from '../styles/components/ServiceCard.module.scss';
import rf from '../requests/RequestFactory';
import DeleteButton from './DeleteButton';
import EditButton from './EditButton';
import ConfirmModal from './ConfirmModal';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { numberWithCommas } from '../utils/utils';
import { notification } from 'antd';

function ServiceCard(props: any) {
  const navigate = useNavigate();
  const { serviceInfo, refreshServiceList, t } = props;
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
  
  const handleEditService = () => {
    navigate(`/service-edit/${serviceInfo._id}`);
  }

  //Delete Service Modal
  const openDeleteModal = () => {
    setIsShowDeleteModal(true);
  }
  const closeDeleteModal = () => {
    setIsShowDeleteModal(false);
  }
  const handleDeleteService = async () => {
    try {
      const res = await rf.getRequest('ServiceRequest').deleteService(serviceInfo._id);
      if(res) {
        notification.success({message: t('Delete service successfully!')});
        refreshServiceList();
        setIsShowDeleteModal(false);
      }
    } catch(error: any) {
      notification.error({message: error.toString()});
      setIsShowDeleteModal(false);
    }
  }

  return (
    <>
      <div className={styles.serviceCard}>
        <div className={styles.serviceInfo}>
          <EditButton onClick={handleEditService} />
          <div className={styles.serviceDescription}>
            <div className={styles.serviceName}><span className={styles.serviceType}>[{t(serviceInfo.type.name)}]</span> {serviceInfo.name}</div>
            <div className={styles.serviceDuration}>
              {serviceInfo.duration.minTime} - {serviceInfo.duration.maxTime} {t('hour(s)')}
            </div>
            {serviceInfo.description ?
              <div className={styles.serviceDes}>{serviceInfo.description}</div> : ''
            }
            <div className={styles.servicePrice}>{numberWithCommas(serviceInfo.price)} VND</div>
          </div>
        </div>
        <DeleteButton onClick={openDeleteModal} />
      </div>
      <ConfirmModal
        isModalVisible={isShowDeleteModal}
        handleConfirm={handleDeleteService}
        handleCancel={closeDeleteModal}
        title={t('Delete Service')}
      >
        {t('Are you sure to delete')} <b>{serviceInfo.name}</b>? {t('This action cannot be undone!')}
      </ConfirmModal>
    </>
  );
}

export default withNamespaces()(ServiceCard);