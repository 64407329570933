import { useNavigate } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import rf from "../requests/RequestFactory";
import { useEffect, useState } from "react";
import { validEmail } from "../utils/utils";
import styles from "../styles/pages/SignUp.module.scss";
import CustomButton from "../components/CustomButton";
// import { sha256 } from "js-sha256";
import { notification } from "antd";

function SignUp({ t }: any) {
  const navigate = useNavigate();
  const [roleList, setRoleList] = useState([]);
  //Params variables
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  // const [password, setPassword] = useState("");
  // const [retypePassword, setRetypePassword] = useState("");
  const handleSignup = async () => {
    if (isDisabledSignup()) {
      return;
    }
    const params = {
      email: email,
      role: role,
      // password: sha256(password),
    };
    try {
      const res = await rf
        .getRequest("AccountRequest")
        .signUpAdminAccount(params);
      if (res) {
        notification.success({ message: t("You have created a new account!") });
        navigate("/");
      }
    } catch (error: any) {
      notification.error({ message: error.toString() });
    }
  };

  const isDisabledSignup = () => {
    return (
      !email ||
      // !password ||
      !validEmail.test(email)
      //|| !validPassword.test(password) ||
      // (password && retypePassword !== password)
    );
  };

  const validateMsg = () => {
    if (!email)
      return t('signupFillWarn');
    if (!validEmail.test(email)) {
      return t('invalidEmailError');
    }
    // if(!validPassword.test(password)) {
    //   return 'Invalid password format! Your password must have minimum six characters, one letter and one number.';
    // }
    // if(password && (retypePassword !== password)) {
    //   return 'Unmatched retyped password';
    // }
    return "";
  };

  useEffect(() => {
    async function getRoleOption() {
      try {
        const res = await rf.getRequest("RoleRequest").getRoleList();
        if (res && res.length > 0) {
          setRoleList(res);
          setRole(res[0]._id);
        }
      } catch (error) {
        console.log("Get role list error", error);
      }
    }
    getRoleOption();
  }, []);
  return (
    <div className={styles.signupPage}>
      <div className={styles.brandWrapper}>
        <div className={styles.brandTitle}>Artist House</div>
        <div className={styles.brandSubtitle}>Admin Page</div>
        <div className={styles.brandDescription}>
          {t('Please sign up a new account')}
        </div>
      </div>
      <div className={styles.loginWrapper}>
        <input
          className={styles.emailInput}
          name="email"
          type="text"
          placeholder={t("Email") + " (abc@xyz.com)"}
          onChange={(e) => setEmail(e.target.value)}
        ></input>

        {roleList && roleList.length > 0 ? (
          <select value={role} onChange={(e) => setRole(e.target.value)}>
            {roleList.map((option: any, index) => (
              <option value={option._id} key={index}>
                {t(option.name)}
              </option>
            ))}
          </select>
        ) : (
          <input
            className={styles.passwordInput}
            name="role"
            type="text"
            placeholder={t("Role") + " (Manger, Staff, ...)"}
            onChange={(e) => setRole(e.target.value)}
          ></input>
        )}
        {/* <input
          className={styles.passwordInput}
          name="password"
          type="password"
          placeholder={t('Password')} 
          onChange={(e) => setPassword(e.target.value)}
        ></input>
        <input
          className={styles.passwordInput}
          name="retype-password"
          type="password"
          placeholder={t('Retype Password')} 
          onChange={(e) => setRetypePassword(e.target.value)}
        ></input> */}
        <div className={styles.validateMsg}>{t(validateMsg())}</div>
        <CustomButton className={styles.loginButton} onClick={handleSignup}>
          {t('sign up')}
        </CustomButton>
        {/* <span
          className={styles.forgotButton}
          onClick={() => navigate('/login')}
        >
          Already have an account? Log in now
        </span> */}
      </div>
    </div>
  );
}

export default withNamespaces()(SignUp);
