import { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import rf from "../../requests/RequestFactory";
import CustomButton from "../../components/CustomButton";
import styles from "../../styles/pages/Service.module.scss";
import FeedbackCard from "../../components/FeedbackCard";
import { useNavigate } from "react-router-dom";

function Feedback({ t }: any) {
  const navigate = useNavigate();
  const [feedbackList, setFeedbackList] = useState([]);
  async function getFeedbackList() {
    const res = await rf.getRequest("FeedbackRequest").getFeedbackList();
    if (res && res.length > 0) {
      setFeedbackList(res);
    } else setFeedbackList([]);
  }
  useEffect(() => {
    getFeedbackList();
  }, []);
  return (
    <div className={styles.servicePage}>
      <CustomButton onClick={() => navigate("/feedback-create")}>
        + {t("new feedback")}
      </CustomButton>
      {feedbackList && feedbackList.length > 0
        ? feedbackList.map(function (feedback: any, index) {
            return (
              <FeedbackCard
                key={index}
                feedbackInfo={feedback}
                refreshFeedbackList={getFeedbackList}
              />
            );
          })
        : ""}
    </div>
  );
}

export default withNamespaces()(Feedback);
