import { withNamespaces } from "react-i18next";
import styles from "../styles/components/StaffCard.module.scss";
import rf from "../requests/RequestFactory";
import DeleteButton from "./DeleteButton";
import EditButton from "./EditButton";
import ConfirmModal from "./ConfirmModal";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { notification } from "antd";

function StaffCard(props: any) {
  const navigate = useNavigate();
  const { staffInfo, refreshStaffList, t } = props;
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);

  const handleEditStaff = async () => {
    navigate(`/staff-edit/${staffInfo._id}`);
  };

  //Delete Staff Modal
  const openDeleteModal = () => {
    setIsShowDeleteModal(true);
  };
  const closeDeleteModal = () => {
    setIsShowDeleteModal(false);
  };
  const handleDeleteStaff = async () => {
    try {
      const res = await rf
        .getRequest("StaffRequest")
        .deleteStaff(staffInfo._id);
      if (res) {
        notification.success({ message: t("Delete staff successfully!") });
        refreshStaffList();
        setIsShowDeleteModal(false);
      }
    } catch (error: any) {
      notification.error({ message: error.toString() });
      setIsShowDeleteModal(false);
    }
  };
  return (
    <>
      <div className={styles.staffCard}>
        <div className={styles.staffInfo}>
          <EditButton onClick={handleEditStaff} />
          <img
            className={styles.staffProfilePicture}
            src={
              staffInfo.imageUrl
                ? staffInfo.imageUrl
                : "/images/blank-profile-picture.png"
            }
            alt="staff-avatar"
          />
          <div className={styles.staffDescription}>
            <div className={styles.staffName}>{staffInfo.name}</div>
            <div className={styles.staffRole}>
              {t(staffInfo.role.name.charAt(0).toUpperCase() +
                staffInfo.role.name.slice(1))} - {staffInfo.exp}
            </div>
            <div className={styles.bookingSummary}>
                <div>
                  <b>{t('Working Hour')}: </b>
                  {staffInfo.startTimeWorking}h - {staffInfo.endTimeWorking}h
                </div>
                <div>
                  <b>{t('Service')}: </b>
                  {staffInfo.services && staffInfo.services.length > 0 ? (
                      <>{staffInfo.services
                        .map((service: any) => service.name)
                        .join(", ")}</>
                  ) : (
                    ""
                  )}
                </div>
            </div>
          </div>
        </div>
        <DeleteButton onClick={openDeleteModal} />
      </div>
      <ConfirmModal
        isModalVisible={isShowDeleteModal}
        handleConfirm={handleDeleteStaff}
        handleCancel={closeDeleteModal}
        title={t("Delete Staff")}
      >
        {t('Are you sure to delete')} <b>{staffInfo.name}</b>? {t('This action cannot be undone!')}
      </ConfirmModal>
    </>
  );
}

export default withNamespaces()(StaffCard);
