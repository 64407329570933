import { useNavigate } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import styles from "../styles/pages/Login.module.scss";
import CustomButton from "../components/CustomButton";
import { sha256 } from "js-sha256";
import { validEmail } from "../utils/utils";
import { notification } from "antd";
import rf from "../requests/RequestFactory";
import { AuthContext } from "..";

function Login({ t }: any) {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { accessToken, setAccessToken, setIsLoggedIn } =
    useContext(AuthContext);

  useEffect(() => {
    if (!!accessToken) {
      navigate("/");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);
  const handleLogin = async () => {
    if (!email || !password) {
      notification.error({ message: t("Email and password cannot be empty!") });
      return;
    }
    if (!validEmail.test(email)) {
      notification.error({ message: t("Invalid email format!") });
      return;
    }
    const params = {
      email: email,
      password: sha256(password),
    };
    try {
      const res = await rf
        .getRequest("AccountRequest")
        .loginAdminAccount(params);
      if (res) {
        notification.success({ message: t("Login successfully!") });
        localStorage.setItem("accountRole", res.role.name);
        localStorage.setItem("accessToken", res.accessToken);
        setAccessToken(res.accessToken);
        setIsLoggedIn(true);
        navigate("/");
      }
    } catch (error: any) {
      notification.error({
        placement: "topRight",
        bottom: 50,
        duration: 3,
        message: error.toString(),
      });
    }
  };
  return (
    <div className={styles.loginPage}>
      <div className={styles.brandWrapper}>
        <div className={styles.brandTitle}>Artist House</div>
        <div className={styles.brandSubtitle}>Admin Page</div>
        <div className={styles.brandDescription}>
          {t('Please login to have all access')}
        </div>
      </div>
      <div className={styles.loginWrapper}>
        <input
          className={styles.emailInput}
          name="email"
          type="text"
          placeholder={t("Email") + " (abc@xyz.com)"}
          onChange={(e) => setEmail(e.target.value)}
        ></input>
        <input
          className={styles.passwordInput}
          name="password"
          type="password"
          placeholder={t("Password")}
          onChange={(e) => setPassword(e.target.value)}
        ></input>
        <CustomButton className={styles.loginButton} onClick={handleLogin}>
          {t('login')}
        </CustomButton>
        <a className={styles.forgotButton} href="/reset-password">
          {t("Forgotten password")}?
        </a>
        {/* <CustomButton onClick={() => navigate("/sign-up")}>
          Sign up
        </CustomButton> */}
      </div>
    </div>
  );
}

export default withNamespaces()(Login);
