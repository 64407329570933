import { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import rf from "../../requests/RequestFactory";
import CustomButton from "../../components/CustomButton";
import styles from "../../styles/pages/Service.module.scss";
import ServiceCard from "../../components/ServiceCard";
import { useNavigate } from "react-router-dom";
import { Pagination } from "antd";

function Service({ t }: any) {
  const SERVICES_PER_PAGE = 10;
  const navigate = useNavigate();
  const [serviceList, setServiceList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalDocs, setTotalDocs] = useState(0);

  async function getServiceList() {
    const params = {
      page: currentPage,
      limit: SERVICES_PER_PAGE,
    };
    const res = await rf.getRequest("ServiceRequest").getServiceList(params);
    if (res && res.totalDocs > 0) {
      setServiceList(res.docs);
      setTotalDocs(res.totalDocs);
    } else {
      setServiceList([]);
      setTotalDocs(0);
    }
  }
  useEffect(() => {
    getServiceList();
    // eslint-disable-next-line
  }, [currentPage]);
  return (
    <div className={styles.servicePage}>
      <CustomButton onClick={() => navigate("/service-create")}>
        + {t("new service")}
      </CustomButton>
      {serviceList && serviceList.length > 0
        ? serviceList.map(function (service: any, index) {
            return (
              <ServiceCard
                key={index}
                serviceInfo={service}
                refreshServiceList={() => getServiceList()}
              />
            );
          })
        : ""}
      {totalDocs > SERVICES_PER_PAGE ? (
        <Pagination
          className={styles.pagination}
          current={currentPage}
          total={totalDocs}
          pageSize={SERVICES_PER_PAGE}
          onChange={(e) => setCurrentPage(e)}
          showSizeChanger={false}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default withNamespaces()(Service);
