import { Modal } from "antd";
import CustomButton from "./CustomButton";
import styles from '../styles/components/ConfirmModal.module.scss';
import { t } from "i18next";
import { withNamespaces } from "react-i18next";

function ConfirmModal({children, ...props}: any) {
  const { title, isModalVisible, handleConfirm, handleCancel} = props;

  return (
    <Modal
      centered
      className={styles.confirmModal}
      visible={isModalVisible}
      closable={false} title={null} footer={null}
    >
      <div className={styles.modalTitle}>
        {title}
      </div>
      { children }
      <div className={styles.buttonWrapper}>
        <CustomButton onClick={handleConfirm}>
          {t('confirm')}
        </CustomButton>
        <CustomButton onClick={handleCancel} buttonColor={'white'}>
          {t('cancel')}
        </CustomButton>
      </div>
    </Modal>
  );
}

export default withNamespaces()(ConfirmModal);