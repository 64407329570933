import { notification } from "antd";

export const validEmail = new RegExp(
  '^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$'
);
//abc@xyz.mno

export const validPassword = new RegExp('^(?=.*?[A-Za-z])(?=.*?[0-9]).{6,}$');
//Minimum six characters, at least one letter and one number

export function numberWithCommas(number: any) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function shortenId(id: string) {
  return id.substring(0,4) + '...' + id.substring(id.length - 5);
}

export function copyToClipboard(textToCopy: string) {
  if (!textToCopy) {
    notification.warn({message: 'Nothing to copy'});
  }
  navigator.clipboard.writeText(textToCopy);
  notification.success({message: 'Copied to Clipboard!'});
}

export const bookingStatusOrder = ['pending', 'confirmed', 'inprogress', 'completed']

export function getDateFromTimestamp(timestamp: any) {
  var a = new Date(timestamp);
  var year = a.getFullYear();
  var month = a.getMonth() + 1;
  var date = a.getDate();
  var time = date + '/' + month + '/' + year;
  return time;
};

export function getTimeString(timeNumber: any) {
  const hour = Math.floor(timeNumber);
  var minus = (timeNumber - Math.floor(timeNumber)) * 60;
  return  `${hour < 10 ? '0' : ''}${hour}:${minus < 10 ? '0' : ''}${minus}`;
}