import React from 'react';
import 'antd/dist/antd.min.css';
import './App.scss';
import { withNamespaces } from 'react-i18next';
import Navbar from "./components/NavBar";
import { AppRoutes } from "./routes";

function App({t}: any) {
  return (
    <div className="App">
      <Navbar />
      <AppRoutes />
    </div>
  );
}

export default withNamespaces()(App);
