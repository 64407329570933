import BaseRequest from './BaseRequest';

export default class BusinessRequest extends BaseRequest {
  getBusinessList() {
    const url = '/businesses';
    return this.get(url, {});
  }
  addBusiness(params: any) {
    const url = '/businesses';
    return this.post(url, params);
  }
  deleteBusiness(id: any) {
    const url = '/businesses';
    return this.delete(url, {id});
  }
}