import { FC, HTMLProps } from 'react';
import styles from '../styles/components/CustomButton.module.scss';

type BUTTON_COLOR = 'primary' | 'success' | 'info' | 'danger' | 'warning' | 'white';

interface ButtonProps extends HTMLProps<HTMLButtonElement> {
  children: any;
  onClick?: (e: any) => void;
  buttonColor?: BUTTON_COLOR;
  isDisabled?: boolean;
  className?: string;
}

const CustomButton: FC<ButtonProps> = ({
  children,
  onClick = () => {
    return null;
  },
  buttonColor = 'primary',
  isDisabled,
  className,
}: ButtonProps) => {
  return (
    <button
      className={`
        ${styles.customButton}
        ${className ? className : ''}
        ${styles[`${buttonColor}`]}
        ${isDisabled ? styles.disabled : ''}
      `}
      onClick={onClick}
    >
      { children }
    </button>
  );
}
 
export default CustomButton;