import { useState } from "react";
import { withNamespaces } from "react-i18next";
import rf from "../../requests/RequestFactory";
import styles from "../../styles/pages/FeedbackCreate.module.scss";
import CustomButton from "../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import { notification } from "antd";

function FeedbackCreate({ t }: any) {
  const navigate = useNavigate();
  //Param variables
  const [customerName, setCustomerName] = useState("");
  const [customerFeedback, setCustomerFeedback] = useState("");
  const [file, setFile] = useState<any>();

  const handleAddFeedback = async () => {
    if(isDisabledAddingFeedback()) {
      notification.error({message: t('Please fill all required fields!')});
      return;
    }
    var profilePictureFile = new FormData();
    profilePictureFile.append("file", file);
    profilePictureFile.append("customerName", customerName);
    profilePictureFile.append("description", customerFeedback);
    profilePictureFile.append("user", "hubot");
    try {
      const res = await rf
        .getRequest("FeedbackRequest")
        .addFeedback(profilePictureFile);
      if (res) {
        notification.success({ message: t("Add feedback successfully!") });
        navigate("/feedback");
      }
    } catch (error: any) {
      notification.error({ message: error.toString() });
    }
  };

  const isDisabledAddingFeedback = () => {
    return !customerName || !customerFeedback;
  }

  function loadFile(e: any) {
    const previewPhoto = document.getElementById(
      "previewPhoto"
    ) as HTMLImageElement;
    console.log("photo file", e.target.files[0]);
    setFile(e.target.files[0]);
    previewPhoto.src = URL.createObjectURL(e.target.files[0]);
    previewPhoto.onload = function () {
      URL.revokeObjectURL(previewPhoto.src); // free memory
    };
  }

  return (
    <div className={styles.feedbackCreatePage}>
      <div className={styles.feedbackInfo}>
        <div className={styles.profilePictureUpload}>
          <img
            className={styles.previewPhoto}
            src="/images/blank-profile-picture.png"
            id="previewPhoto"
            alt="preview-avatar"
          />
          <CustomButton className={styles.uploadButton}>
            {t("choose profile picture")}
            <input
              className={styles.uploadInput}
              type="file"
              accept="image/*"
              onChange={(e) => loadFile(e)}
            />
          </CustomButton>
          <div className={styles.profilePictureHint}>
            {t("profilePictureHint")}
          </div>
        </div>
        <div className={styles.feedbackDescription}>
          <div className={styles.infoTitle}>{t('Customer Name')}<span className='requiredFieldIcon'>*</span></div>
          <input
            className={styles.emailInput}
            name="name"
            type="text"
            placeholder={t("Full Name")}
            onChange={(e) => setCustomerName(e.target.value)}
          ></input>
          <div className={styles.infoTitle}>{t("Customer Feedback")}<span className='requiredFieldIcon'>*</span></div>
          <input
            className={styles.emailInput}
            name="feedback"
            type="text"
            placeholder={t("Feedback")}
            onChange={(e) => setCustomerFeedback(e.target.value)}
          ></input>
        </div>
      </div>
      <div className={styles.buttonWrapper}>
        <CustomButton onClick={handleAddFeedback}>{t('add feedback')}</CustomButton>
        <CustomButton onClick={() => navigate("/feedback")} buttonColor={'white'}>
          {t('cancel')}
        </CustomButton>
      </div>
    </div>
  );
}

export default withNamespaces()(FeedbackCreate);
