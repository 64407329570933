import { withNamespaces } from "react-i18next";
import styles from "../styles/components/BookingCard.module.scss";
import rf from "../requests/RequestFactory";
import EditButton from "./EditButton";
import DeleteButton from "./DeleteButton";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import ConfirmModal from "./ConfirmModal";
import { notification, Collapse } from "antd";
import {
  shortenId,
  copyToClipboard,
  bookingStatusOrder,
  getDateFromTimestamp,
  getTimeString,
} from "../utils/utils";
import CustomButton from "./CustomButton";
const { Panel } = Collapse;

function BookingCard(props: any) {
  const navigate = useNavigate();
  const { bookingInfo, refreshBookingList, t } = props;
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
  const [cancelledBookingNumber, setCancelledBookingNumber] = useState(0);
  const handleEditBooking = async () => {
    navigate(`/booking-edit/${bookingInfo._id}`);
  };

  //Delete Booking Modal
  const openDeleteModal = () => {
    setIsShowDeleteModal(true);
  };
  const closeDeleteModal = () => {
    setIsShowDeleteModal(false);
  };

  const getHandleBookingProgressText = () => {
    switch (bookingInfo.status) {
      case "pending":
        return "confirm booking";
      case "confirmed":
        return "mark in progress";
      case "inprogress":
        return "mark done";
      case "completed":
        return "completed";
      case "cancelled":
        return "cancelled";
      default:
        return "";
    }
  };

  const getButtonStyle = () => {
    switch (bookingInfo.status) {
      case "pending":
        return "success";
      case "confirmed":
        return "info";
      case "inprogress":
        return "primary";
      case "completed":
        return "primary";
      case "cancelled":
        return "primary";
      default:
        return "primary";
    }
  };

  const handleBookingProgress = async (e: any) => {
    e.stopPropagation();
    if (
      bookingInfo.status === "completed" ||
      bookingInfo.status === "cancelled"
    )
      return;
    const newStatus =
      bookingStatusOrder[bookingStatusOrder.indexOf(bookingInfo.status) + 1];
    await changeBookingStatus(newStatus);
  };
  const getCancelledBooking = async () => {
    const params = {
      status: "cancelled",
      customer: bookingInfo.customer._id,
    };
    const res = await rf.getRequest("BookingRequest").getBookingList(params);
    if (res) {
      setCancelledBookingNumber(res.length);
    }
  };

  useEffect(() => {
    getCancelledBooking();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingInfo]);
  const changeBookingStatus = async (newStatus: string) => {
    try {
      const params = {
        _id: bookingInfo._id,
        branch: bookingInfo.branch,
        customer: bookingInfo.customer,
        staff: bookingInfo.staff,
        startTimeBooking: bookingInfo.startTimeBooking,
        startDateBooking: bookingInfo.startDateBooking,
        services: bookingInfo.services,
        status: newStatus,
      };
      const res = await rf.getRequest("BookingRequest").editBooking(params);
      if (res) {
        notification.success({
          message: t("Update booking status successfully!"),
        });
        refreshBookingList();
      }
    } catch (error: any) {
      notification.error({ message: error.toString() });
    }
  };
  const cancelBooking = async () => {
    const params = {
      email: bookingInfo.customer.email,
    };
    try {
      const res = await rf
        .getRequest("BookingRequest")
        .cancelBooking(bookingInfo._id, params);
      if (res) {
        notification.success({
          message: t("Cancelled booking status successfully!"),
        });
        refreshBookingList();
      }
    } catch (error: any) {
      notification.error({ message: error.toString() });
    }
  };

  return (
    <>
      <Collapse bordered={false} className={styles.bookingCard}>
        <Panel
          style={{ width: "100%" }}
          key={bookingInfo._id}
          header={
            <div className={styles.cardHeader}>
              <div className={styles.bookingIdWrapper}>
                <div
                  className={`${styles.bookingStatus} ${
                    styles[`${bookingInfo.status}`]
                  }`}
                >
                  {t(bookingInfo.status)}
                </div>
                <div className={styles.bookingName}>
                  <span className={styles.bookingText}>{t("Booking")}</span> #
                  {shortenId(bookingInfo._id)}
                  {cancelledBookingNumber > 0 && (
                    <>
                      {" "}
                      (<span style={{ color: "#e74a3b" }}>&#9888;</span>
                      {cancelledBookingNumber})
                    </>
                  )}
                </div>
              </div>
              <div className={styles.bookingTime}>
                {getTimeString(bookingInfo.startTimeBooking)} -{" "}
                {getDateFromTimestamp(bookingInfo.startDateBooking)}
              </div>
              <div className={styles.bookingSummary}>
                <div>
                  <b>{t("Customer")}: </b>
                  {bookingInfo.customer.name}
                </div>
                <div>
                  <b>{t("Staff")}: </b>
                  {bookingInfo.staff?.name}
                </div>
                <div>
                  <b>{t("Services")}: </b>
                  {bookingInfo.services && bookingInfo.services.length > 0 ? (
                    <>
                      {bookingInfo.services
                        .map((service: any) => service.name)
                        .join(", ")}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {bookingInfo.status !== "completed" &&
              bookingInfo.status !== "cancelled" ? (
                <div className={styles.buttonWrapper}>
                  <CustomButton
                    onClick={handleBookingProgress}
                    buttonColor={getButtonStyle()}
                  >
                    {t(getHandleBookingProgressText())}
                  </CustomButton>
                  <CustomButton
                    onClick={() => copyToClipboard(bookingInfo.customer.phone)}
                    buttonColor={"white"}
                  >
                    {t("call customer")}
                  </CustomButton>
                </div>
              ) : (
                ""
              )}
            </div>
          }
        >
          <div className={styles.bookingDetail}>
            <div className={styles.bookingInfo}>
              <EditButton onClick={handleEditBooking} />
              <div className={styles.bookingDescription}>
                {bookingInfo.services && bookingInfo.services.length > 0 ? (
                  <div className={styles.servivces}>
                    <b>{t("Services")}: </b>
                    {bookingInfo.services
                      .map((service: any) => service.name)
                      .join(", ")}
                  </div>
                ) : (
                  ""
                )}
                <div className={styles.staffName}>
                  <b>{t("Staff")}: </b>
                  {bookingInfo.staff?.name}
                </div>
                -------------------------
                <div className={styles.customerName}>
                  <b>{t("Customer")}: </b>
                  {bookingInfo.customer.name}
                </div>
                <div className={styles.customerContact}>
                  <span>
                    <b>{t("Email")}: </b>
                    {bookingInfo.customer.email || "Unknown"}
                  </span>
                  {/* <CopyButton onClick={() => copyToClipboard(bookingInfo.customer.email)} /> */}
                </div>
                <div className={styles.customerContact}>
                  <span>
                    <b>{t("Phone")}: </b>
                    {bookingInfo.customer.phone || "Unknown"}
                  </span>
                  {/* <CopyButton onClick={() => copyToClipboard(bookingInfo.customer.phone)} /> */}
                </div>
                <div className={styles.customerContact}>
                  <span>
                    <b>{t("Date of birth")}: </b>
                    {bookingInfo.customer.dob || "Unknown"}
                  </span>
                  {/* <CopyButton onClick={() => copyToClipboard(bookingInfo.customer.phone)} /> */}
                </div>
                {bookingInfo.comment ? (
                  <>
                    <div className={styles.customerName}>
                      <b>{t("Comment")}: </b>
                      {bookingInfo.comment}
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
            {bookingInfo.status !== "completed" &&
              bookingInfo.status !== "cancelled" && (
                <DeleteButton onClick={openDeleteModal} />
              )}
          </div>
        </Panel>
      </Collapse>
      <ConfirmModal
        isModalVisible={isShowDeleteModal}
        handleConfirm={() => {
          cancelBooking();
          closeDeleteModal();
        }}
        handleCancel={closeDeleteModal}
        title={t("Cancel Booking")}
      >
        {t("Are you sure to cancel booking")} <b>#{bookingInfo._id}</b>?
      </ConfirmModal>
    </>
  );
}

export default withNamespaces()(BookingCard);
