import { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import rf from "../../requests/RequestFactory";
import CustomButton from "../../components/CustomButton";
import styles from "../../styles/pages/Booking.module.scss";
import { useNavigate } from "react-router-dom";
import BookingCard from "../../components/BookingCard";
import { Pagination } from "antd";
import { BookingOverview } from "../../types";

function Booking({ t }: any) {
  const navigate = useNavigate();

  const BOOKINGS_PER_PAGE = 3;
  const [status, setStatus] = useState("pending");
  const [bookingList, setBookingList] = useState([]);
  const [currentBookingPage, setCurrentBookingPage] = useState(1);
  const [totalBookingDocs, setTotalBookingDocs] = useState(0);

  const UPCOMING_PER_PAGE = 3;
  const [upcomingBooking, setUpcomingBooking] = useState([]);
  const [currentUpcomingPage, setCurrentUpcomingPage] = useState(1);
  const [totalUpcomingDocs, setTotalUpcomingDocs] = useState(0);
  const [overview, setOverview] = useState<BookingOverview>({
    cancelled: 0,
    completed: 0,
    confirmed: 0,
    inprogress: 0,
    pending: 0,
  });
  async function getBookingOverview() {
    try {
      const res = await rf.getRequest("BookingRequest").getOverview();
      if (res) {
        setOverview(res);
      }
    } catch (error) {
      console.log(error);
    }
  }
  async function getBookingList() {
    const params = {
      status: status,
      page: currentBookingPage,
      limit: BOOKINGS_PER_PAGE,
    };
    const res = await rf.getRequest("BookingRequest").getBookingList(params);
    if (res && res.totalDocs > 0) {
      setBookingList(res.docs);
      setTotalBookingDocs(res.totalDocs);
    } else {
      setBookingList([]);
      setTotalBookingDocs(0);
    }
  }

  async function getUpcomingBooking() {
    const currentTime = Date.now();
    const params = {
      startDateBooking:
        new Date(
          new Date(currentTime).toISOString().substring(0, 10)
        ).valueOf() + 86400000,
      page: currentUpcomingPage,
      limit: UPCOMING_PER_PAGE,
    };
    const res = await rf.getRequest("BookingRequest").getBookingList(params);
    if (res && res.totalDocs > 0) {
      setUpcomingBooking(res.docs);
      setTotalUpcomingDocs(res.totalDocs);
    } else {
      setUpcomingBooking([]);
      setTotalUpcomingDocs(0);
    }
  }

  const handlePageChange = (status: string) => {
    setStatus(status);
    setCurrentBookingPage(1);
  };

  useEffect(() => {
    getUpcomingBooking();
    // eslint-disable-next-line
  }, [currentUpcomingPage]);

  useEffect(() => {
    getBookingList();
    // eslint-disable-next-line
  }, [status, currentBookingPage]);

  useEffect(() => {
    getBookingOverview();
  }, []);
  return (
    <div className={styles.bookingPage}>
      <div className={styles.infoTitle}>{t("Upcoming Booking")}</div>
      {upcomingBooking && upcomingBooking.length > 0 ? (
        upcomingBooking.map(function (booking: any, index) {
          return (
            <BookingCard
              bookingInfo={booking}
              refreshBookingList={() => {
                getUpcomingBooking();
                getBookingOverview();
              }}
              key={index}
            />
          );
        })
      ) : (
        <div className={styles.noResult}>{t("No Upcoming Booking")}</div>
      )}
      {totalUpcomingDocs > UPCOMING_PER_PAGE ? (
        <Pagination
          className={styles.pagination}
          current={currentUpcomingPage}
          total={totalUpcomingDocs}
          pageSize={UPCOMING_PER_PAGE}
          onChange={(e) => setCurrentUpcomingPage(e)}
        />
      ) : (
        ""
      )}
      <div className={styles.header}>
        <CustomButton onClick={() => navigate("/booking-create")}>
          + {t("new booking")}
        </CustomButton>
        <CustomButton
          onClick={() => navigate("/change-password")}
          buttonColor={"white"}
        >
          {t("change password")}
        </CustomButton>
      </div>
      <div className={"tab"} style={{ marginTop: "24px" }}>
        <button
          className={`${"tabButton"} ${status === "pending" ? "active" : ""}`}
          onClick={() => handlePageChange("pending")}
        >
          <img src="/icons/history.svg" alt="history" />
          {t("pending")} ({overview.pending})
        </button>
        <button
          className={`${"tabButton"} ${status === "confirmed" ? "active" : ""}`}
          onClick={() => handlePageChange("confirmed")}
        >
          <img src="/icons/check.svg" alt="check" />
          {t("confirmed")} ({overview.confirmed})
        </button>
        <button
          className={`${"tabButton"} ${
            status === "inprogress" ? "active" : ""
          }`}
          onClick={() => handlePageChange("inprogress")}
        >
          <img src="/icons/user-clock.svg" alt="user-clock" />
          {t("inprogress")} ({overview.inprogress})
        </button>
      </div>
      {bookingList && bookingList.length > 0 ? (
        bookingList.map(function (booking: any, index) {
          return (
            <BookingCard
              bookingInfo={booking}
              refreshBookingList={() => {
                getBookingList();
                getBookingOverview();
              }}
              key={index}
            />
          );
        })
      ) : (
        <div className={styles.noResult}>
          <img src="/icons/search.svg" alt="search" />
          {t("No Result Found")}
        </div>
      )}
      {totalBookingDocs > BOOKINGS_PER_PAGE ? (
        <Pagination
          className={styles.pagination}
          current={currentBookingPage}
          total={totalBookingDocs}
          pageSize={BOOKINGS_PER_PAGE}
          onChange={(e) => setCurrentBookingPage(e)}
          showSizeChanger={false}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default withNamespaces()(Booking);
