import { useEffect, useState } from "react";
import { withNamespaces } from 'react-i18next';
import rf from '../../requests/RequestFactory';
import styles from '../../styles/pages/ServiceCreate.module.scss';
import CustomButton from "../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import { notification } from "antd";

function ServiceCreate({t}: any) {
  const navigate = useNavigate();
  const [businessList, setBusinessList] = useState([]);
  //Param variables
  const [name, setName] = useState('');
  const [price, setPrice] = useState('');
  const [minTime, setMinTime] = useState(0);
  const [maxTime, setMaxTime] = useState(1);
  const [type, setType] = useState('');
  const [description, setDescription] = useState('')

  const handleAddService = async () => {
    if(isDisabledAddingService()) {
      notification.error({message: t('Please fill all required fields!')});
      return;
    }
    const params = {
      name: name,
      price: price,
      duration: {
        minTime: minTime,
        maxTime: maxTime
      },
      type: type,
      description: description
    }
    try {
      const res = await rf.getRequest('ServiceRequest').addService(params);
      if(res) {
        notification.success({message: t('Add service successfully!')});
        navigate('/service');
      }
    } catch(error: any) {
      notification.error({message: error.toString()});
    }
  };

  const isDisabledAddingService = () => {
    return !name || !price || !minTime || !maxTime || !type || !description;
  }

  async function getBusinessOption() {
    const res = await rf.getRequest('BusinessRequest').getBusinessList();
    if (res && res.length > 0) {
      setBusinessList(res);
      setType(res[0]);
    }
  }

  useEffect(() => {
    getBusinessOption();
  }, []);

  return (
    <div className={styles.serviceCreatePage}>
      <div className={styles.serviceInfo}>
        <div className={styles.serviceDescription}>
          <div className={styles.infoTitle}>{t('Service Name')}<span className='requiredFieldIcon'>*</span></div>
          <input
            className={styles.emailInput}
            name="name"
            type="text"
            placeholder={t('Service Name')}
            onChange={(e) => setName(e.target.value)}
          ></input>
          {
            (businessList && businessList.length > 0) ? 
              <>
                <div className={styles.infoTitle}>{t('Category')}<span className='requiredFieldIcon'>*</span></div>
                <select value={type} onChange={e => setType(e.target.value)}>
                  {businessList.map((option: any, index) => (
                    <option value={option._id} key={index}>{t(option.name)}</option>
                  ))}
                </select>
              </> : ''
          }
          <div className={styles.infoTitle}>{t('Service Duration')} ({t('Hour')})<span className='requiredFieldIcon'>*</span></div>
          <div className={styles.durationWrapper}>
            <div>{t('from')}</div>
            <input
              className={styles.durationInput}
              name="minTime"
              type="number"
              pattern="[0-9]+([\.,][0-9]+)?" step="0.1"
              placeholder={t('Min Time')}
              onChange={(e) => setMinTime(Number(e.target.value))}
            ></input>
            <div>{t('to')}</div>
            <input
              className={styles.durationInput}
              name="maxTime"
              type="number"
              pattern="[0-9]+([\.,][0-9]+)?" step="0.1"
              placeholder={t('Max Time')}
              onChange={(e) => setMaxTime(Number(e.target.value))}
            ></input>
          </div> 
          <div className={styles.infoTitle}>{t('Service Description')}<span className='requiredFieldIcon'>*</span></div>
          <input
            className={styles.emailInput}
            name="description"
            type="text"
            placeholder={t('Some description about the service')}
            onChange={(e) => setDescription(e.target.value)}
          ></input>
          <div className={styles.infoTitle}>{t('Service Price')} (VND)<span className='requiredFieldIcon'>*</span></div>
          <input
            className={styles.emailInput}
            name="price"
            type="number"
            pattern="\d*"
            placeholder={t('Price')}
            onChange={(e) => setPrice(e.target.value)}
          ></input>
        </div>
      </div>
      <div className={styles.buttonWrapper}>
        <CustomButton onClick={handleAddService}>
          {t('add service')}
        </CustomButton>
        <CustomButton onClick={() => navigate('/service')} buttonColor={'white'}>
          {t('cancel')}
        </CustomButton>
      </div>
    </div>
  );
}

export default withNamespaces()(ServiceCreate);