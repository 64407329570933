import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";

//Account
import Account from "./pages/Account/Account";

//Booking
import Booking from "./pages/Booking/Booking";
import BookingHistory from "./pages/Booking/BookingHistory";
import BookingCreate from "./pages/Booking/BookingCreate";
import BookingEdit from "./pages/Booking/BookingEdit";

//Staff
import Staff from "./pages/Staff/Staff";
import StaffCreate from "./pages/Staff/StaffCreate";
import StaffEdit from "./pages/Staff/StaffEdit";

//Service
import Service from "./pages/Service/Service";
import ServiceCreate from "./pages/Service/ServiceCreate";
import ServiceEdit from "./pages/Service/ServiceEdit";

//Service
import Feedback from "./pages/Feedback/Feedback";
import FeedbackCreate from "./pages/Feedback/FeedbackCreate";
import FeedbackEdit from "./pages/Feedback/FeedbackEdit";
import { useContext } from "react";
import { AuthContext } from ".";
import ChangePassword from "./pages/ChangePassword";
import ResetPassword from "./pages/ResetPassword";

const ProtectedRoute = ({ isAuth, redirectPath = "/login" }: any) => {
  if (!isAuth) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};

export function AppRoutes() {
  const { isLoggedIn } = useContext(AuthContext);
  return (
    <Routes>
      {/* <Route
        element={<ProtectedRoute isAuth={!accessToken} redirectPath={"/"} />}
      > */}
      <Route path="login" element={<Login />} />
      <Route path="reset-password" element={<ResetPassword />} />

      {/* </Route> */}

      <Route element={<ProtectedRoute isAuth={isLoggedIn} />}>
        <Route path="sign-up" element={<SignUp />} />
        <Route path="change-password" element={<ChangePassword />} />

        <Route path="/" element={<Booking />} />
        <Route path="booking-history" element={<BookingHistory />} />
        <Route path="booking-create" element={<BookingCreate />} />
        <Route path="booking-edit/:bookingId" element={<BookingEdit />} />

        <Route path="account" element={<Account />} />

        <Route path="staff" element={<Staff />} />
        <Route path="staff-create" element={<StaffCreate />} />
        <Route path="staff-edit/:staffId" element={<StaffEdit />} />

        <Route path="service" element={<Service />} />
        <Route path="service-create" element={<ServiceCreate />} />
        <Route path="service-edit/:serviceId" element={<ServiceEdit />} />

        <Route path="feedback" element={<Feedback />} />
        <Route path="feedback-create" element={<FeedbackCreate />} />
        <Route path="feedback-edit/:feedbackId" element={<FeedbackEdit />} />
      </Route>
    </Routes>
  );
}
