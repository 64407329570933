import BaseRequest from './BaseRequest';

export default class BranchRequest extends BaseRequest {
  getBranchList() {
    const url = '/branch';
    return this.get(url, {});
  }
  // getBookingById(id: any) {
  //   const url = `/booking/${id}`;
  //   return this.get(url, {});
  // }
  // addBooking(params: any) {
  //   const url = '/booking';
  //   return this.post(url, params);
  // }
  // deleteBooking(id: any) {
  //   const url = '/booking';
  //   return this.delete(url, {_id: id});
  // }
  // editBooking(params: any) {
  //   const url = '/booking';
  //   return this.put(url, params)
  // }
}