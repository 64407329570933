import { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import rf from "../../requests/RequestFactory";
import CustomButton from "../../components/CustomButton";
import styles from "../../styles/pages/Account.module.scss";
import AccountCard from "../../components/AccountCard";
import { useNavigate } from "react-router-dom";
import { Pagination } from "antd";

function Account({ t }: any) {
  const ACCOUNTS_PER_PAGE = 10;
  const navigate = useNavigate();
  const [accountList, setAccountList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totaltotalDocs, setTotaltotalDocs] = useState(0);

  async function getAccountList() {
    const params = {
      page: currentPage,
      limit: ACCOUNTS_PER_PAGE,
    };
    const res = await rf.getRequest("AccountRequest").getUsers(params);
    if (res && res.totalDocs > 0) {
      setAccountList(res.docs);
      setTotaltotalDocs(res.totalDocs);
    } else {
      setAccountList([]);
      setTotaltotalDocs(0);
    }
  }

  useEffect(() => {
    getAccountList();
    // eslint-disable-next-line
  }, [currentPage]);
  return (
    <div className={styles.accountPage}>
      <CustomButton onClick={() => navigate("/sign-up")}>
        + {t("new user")}
      </CustomButton>
      {accountList && accountList.length > 0
        ? accountList.map(function (account: any, index) {
            return (
              <AccountCard
                key={index}
                accountInfo={account}
                refreshAccountList={getAccountList}
              />
            );
          })
        : ""}
      {totaltotalDocs > ACCOUNTS_PER_PAGE ? (
        <Pagination
          className={styles.pagination}
          current={currentPage}
          total={totaltotalDocs}
          pageSize={ACCOUNTS_PER_PAGE}
          onChange={(e) => setCurrentPage(e)}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default withNamespaces()(Account);
