import AccountRequest from './AccountRequest';
import BookingRequest from './BookingRequest';
import BranchRequest from './BranchRequest';
import BusinessRequest from './BusinessRequest';
import FeedbackRequest from './FeedbackRequest';
import RoleRequest from './RoleRequest';
import ServiceRequest from './ServiceRequest';
import StaffRequest from './StaffRequest';

const requestMap = {
  AccountRequest,
  BookingRequest,
  BranchRequest,
  BusinessRequest,
  FeedbackRequest,
  RoleRequest,
  ServiceRequest,
  StaffRequest
};

const instances = {};

export default class RequestFactory {
  static getRequest(classname: string) {
    // @ts-ignore
    const RequestClass = requestMap[classname];
    if (!RequestClass) {
      throw new Error(`Invalid request class name: ${classname}`);
    }

    // @ts-ignore
    let requestInstance = instances[classname];
    if (!requestInstance) {
      requestInstance = new RequestClass();
      // @ts-ignore
      instances[classname] = requestInstance;
    }

    return requestInstance;
  }
}
