import React, { Component } from "react";
import RightMenu from "./RightMenu";
import { Drawer, Button } from "antd";
import "antd/dist/antd.min.css";
import styles from "../styles/components/NavBar.module.scss";
class Navbar extends Component {
  state = {
    current: "mail",
    visible: false
  };
  showDrawer = () => {
    this.setState({
      visible: true
    });
  };
  onClose = () => {
    this.setState({
      visible: false
    });
  };
  render() {
    return (
      <nav className={styles.menuBar}>
        <div className={styles.businessInfo}>
          <div className={styles.businessTitle} onClick={() => window.open('/',"_self")}>
            <img src="/images/logo.png" alt="brand-logo"/>
          </div>
          {/* <div className={styles.businessSubtitle}>AESTHETICS CLINIC</div> */}
        </div>
        <div className={styles.menuCon}>
          <div className={styles.rightMenu}>
            <RightMenu direction="horizontal"/>
          </div>
          <Button className={styles.barsMenu} type="primary" onClick={this.showDrawer}>
            <img src="/icons/menu-button.svg" alt="menu-button-icon" />
          </Button>
          <Drawer
            className={styles.drawer}
            placement="right"
            closable={false}
            onClose={this.onClose}
            visible={this.state.visible}
          >
            <RightMenu direction="vertical" handleCollapse={this.onClose}/>
          </Drawer>
        </div>
      </nav>
    );
  }
}
export default Navbar;
