import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
import { setAuthorizationToRequest } from './utils/authorization';
export interface AuthState {
  accessToken: string;
  isLoggedIn: boolean;
  setAccessToken: (accessToken: string) => void;
  setIsLoggedIn: (loggedIn: boolean) => void;
}
const initialValue: AuthState = {
  accessToken: "",
  setAccessToken: () => {},
  isLoggedIn: true,
  setIsLoggedIn: () => {},
};
export const AuthContext = React.createContext(initialValue);
const Root = () => {
  const [accessToken, setAccessToken] = useState<any>();
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  useEffect(() => {
    const tmpAccessToken = localStorage.getItem("accessToken");
    setAccessToken(tmpAccessToken);
    if (!tmpAccessToken) {
      setIsLoggedIn(false);
    }
  }, []);

  useEffect(() => {
    setAuthorizationToRequest(accessToken);
  }, [accessToken]);
  return (
    <React.StrictMode>
      <BrowserRouter>
        <AuthContext.Provider
          value={{ accessToken, setAccessToken, isLoggedIn, setIsLoggedIn }}
        >
          <App />
        </AuthContext.Provider>
      </BrowserRouter>
    </React.StrictMode>
  );
};

ReactDOM.render(<Root />, document.getElementById("root") as HTMLElement);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
