import { withNamespaces } from "react-i18next";
import styles from "../styles/components/AccountCard.module.scss";
import rf from "../requests/RequestFactory";
import DeleteButton from "./DeleteButton";
import EditButton from "./EditButton";
import ConfirmModal from "./ConfirmModal";
import { useEffect, useState } from "react";
import { notification } from "antd";

function AccountCard(props: any) {
  const { accountInfo, refreshAccountList, t } = props;
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
  const [isShowEditRoleModal, setIsShowEditRoleModal] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const [roleId, setRoleId] = useState("");

  useEffect(() => {
    async function getRoleOption() {
      try {
        const res = await rf.getRequest("RoleRequest").getRoleList();
        if (res) {
          setRoleList(res);
        } else {
          setRoleList([]);
        }
      } catch (error) {
        console.log("Get role list error", error);
      }
    }
    if (isShowEditRoleModal) {
      getRoleOption();
    }
    // eslint-disable-next-line
  }, [isShowEditRoleModal]);

  //Edit Role Modal
  const openEditRoleModal = (roleId: string) => {
    setRoleId(roleId);
    setIsShowEditRoleModal(true);
  };
  const closeEditRoleModal = () => {
    setIsShowEditRoleModal(false);
  };
  const handleEditRoleAccount = async () => {
    const params = {
      email: accountInfo.email,
      role: roleId,
    };
    try {
      const res = await rf.getRequest("AccountRequest").updateUser(params);
      if (res) {
        notification.success({ message: t("Update role successfully!") });
        refreshAccountList();
        setIsShowEditRoleModal(false);
      }
    } catch (error: any) {
      notification.error({ message: error.toString() });
      setIsShowEditRoleModal(false);
    }
  };
  //Delete Account Modal
  const openDeleteModal = () => {
    setIsShowDeleteModal(true);
  };
  const closeDeleteModal = () => {
    setIsShowDeleteModal(false);
  };
  const handleDeleteAccount = async () => {
    try {
      const res = await rf
        .getRequest("AccountRequest")
        .deleteUser(accountInfo.id);
      if (res) {
        notification.success({ message: t("Delete account successfully!") });
        refreshAccountList();
        setIsShowDeleteModal(false);
      }
    } catch (error: any) {
      notification.error({ message: error.toString() });
      setIsShowDeleteModal(false);
    }
  };
  return (
    <>
      <div className={styles.accountCard}>
        <div className={styles.accountInfo}>
          <EditButton onClick={() => openEditRoleModal(accountInfo.role._id)} />
          <div className={styles.customerDescription}>
            <div className={styles.customerName}>{accountInfo.email}</div>
            <div className={styles.customerRole}>
              {t(accountInfo.role.name)}
            </div>
          </div>
        </div>
        <DeleteButton onClick={openDeleteModal} />
      </div>
      <ConfirmModal
        isModalVisible={isShowDeleteModal}
        handleConfirm={handleDeleteAccount}
        handleCancel={closeDeleteModal}
        title={t("Delete Account")}
      >
        {t("Are you sure to delete")} <b>{accountInfo.email}</b>?{" "}
        {t("This action cannot be undone!")}
      </ConfirmModal>
      <ConfirmModal
        isModalVisible={isShowEditRoleModal}
        handleConfirm={handleEditRoleAccount}
        handleCancel={closeEditRoleModal}
        title={t("Edit Role")}
      >
        <div className={styles.editRoleModal}>
          {t("Please choose a new role for")} <b>{accountInfo.email}</b> (
          {t("Current: ")}
          {t(accountInfo.role.name)})
          <div className={styles.selectRole}>
            {roleList && roleList.length > 0 ? (
              <select
                value={roleId}
                onChange={(e) => setRoleId(e.target.value)}
              >
                {roleList.map((option: any, index) => (
                  <option value={option._id} key={index}>
                    {t(option.name)}
                  </option>
                ))}
              </select>
            ) : (
              ""
            )}
          </div>
        </div>
      </ConfirmModal>
    </>
  );
}

export default withNamespaces()(AccountCard);
