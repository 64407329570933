import BaseRequest from './BaseRequest';

export default class FeedbackRequest extends BaseRequest {
  getFeedbackList() {
    const url = '/feedback';
    return this.get(url, {});
  }
  getFeedbackById(id: any) {
    const url = `/feedback/${id}`;
    return this.get(url, {});
  }
  addFeedback(params: any) {
    const url = '/feedback';
    return this.post(url, params);
  }
  deleteFeedback(id: any) {
    const url = `/feedback/${id}`;
    return this.put(url, {});
  }
  editFeedback(params: any) {
    const url = '/feedback';
    return this.put(url, params)
  }
}